import React from "react";
import Hero from "../components/blogs/hero";
import Blogs from "../components/blogs/blogs";
function BlogPage() {
  return (
    <>
      <Hero />
      <Blogs />
    </>
  );
}

export default BlogPage;
