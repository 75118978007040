import React from "react";
import Hero from "../components/contact/hero";
import Form from "../components/contact/form";
function ContactPage() {
  return (
    <>
      <Hero />
      <Form />
    </>
  );
}

export default ContactPage;
