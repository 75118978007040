import React from "react";
import Hero from "../components/blogPages/introductionToHTML/hero";
import Section from '../components/blogPages/introductionToHTML/section'
function IntroPage() {
  return (
    <>
      <Hero />
      <Section />
    </>
  );
}

export default IntroPage;