import Hero from "../components/projects/hero";
import Section from "../components/projects/section";

function ProjectPage() {
  return (
    <>
      <Hero />
      <Section />
    </>
  );
}

export default ProjectPage;
